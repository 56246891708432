import { BatteryCharging50Rounded, HouseRounded, SolarPowerRounded } from "@mui/icons-material";
import { FormControl, FormHelperText, FormLabel, Grid2 } from "@mui/material";
import { Stack } from "@mui/system";
import { useField } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InstallationClassificationCard from "./InstallationClassificationCard";

//installationClassificationsConsumption not updated and not being used
export const installationClassificationsConsumption = [
    "ic",
    "ic_strg",
    "ic_prod",
    "ic_prod_strg"
];

export const installationClassificationsProduction = [
    "ic_prod",
    "ic_prod_strg",
    "ip",
    "ip_strg"
];

export const installationClassificationsStorage = [
    "ic_prod_strg",
    "ip_strg",
    "ia"
];

const InstallationClassificationSelectSlider = ({installationType, ...props }) => {

    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props);

    const installationClassifications = useMemo(() => [
        {
            type:"ic",
            code: "ic",
            icon: <HouseRounded/>,
            name: "consumptionInstallationNoProduction",
            description: "consumptionInstallationNoProductionDescription",
        },
        {
            type:"ic",
            code: "ic_prod",
            icon: (
                <Stack  direction="row" spacing={1}>
                    <HouseRounded/>
                    <SolarPowerRounded/>
                </Stack>
            ),
            name: "consumptionInstallationWithProduction",
            description: "consumptionInstallationWithProductionDescription",
        },
        {
            type:"ic",
            code: "ic_prod_strg",
            icon: (
                <Stack  direction="row" spacing={1}>
                    <HouseRounded/>
                    <SolarPowerRounded/>
                    <BatteryCharging50Rounded/>
                </Stack>
            ),
            name: "consumptionInstallationWithProductionAndStorage",
            description: "consumptionInstallationWithProductionAndStorageDescription",
        },
        {
            type:"ic",
            code: "ic_strg",
            icon: (
                <Stack  direction="row" spacing={1}>
                    <HouseRounded color="disabled"/>
                    <BatteryCharging50Rounded color="disabled"/>
                </Stack>
            ),
            name: "consumptionInstallationNoProductionWithStorage",
            description: "consumptionInstallationNoProductionWithStorageDescription",
            disabled: true
        },
        {
            type:"ip",
            code: "ip",
            icon: (
                <Stack  direction="row" spacing={1}>
                    <SolarPowerRounded/>
                </Stack>
            ),
            name: "productionInstallation",
            description: "productionInstallationDescription",
        },
        {
            type:"ip",
            code: "ip_strg",
            icon: (
                <Stack  direction="row" spacing={1}>
                    <SolarPowerRounded/>
                    <BatteryCharging50Rounded/>
                </Stack>
            ),
            name: "productionInstallationWithStorage",
            description: "productionInstallationWithStorageDescription",
        },
        {
            type:"ia",
            code: "ia",
            icon: (
                <Stack  direction="row" spacing={1}>
                    <SolarPowerRounded/>
                </Stack>
            ),
            name: "storageInstallation",
            description: "storageInstallationDescription",
        },
    ], []);

    const [selected, setSelected] = useState(field.value ? installationClassifications.filter((t) => t.code === field.value?.code)[0] : null);


    const onInstallationSelected = (classification) => {
        setSelected(classification);
        helpers.setValue(classification);
        if(props.onChange) {
            props.onChange(classification);
        }
    }

    if(!installationType){
        return (
            <FormControl>
                <FormLabel>{t("label.form.installationClassification")}</FormLabel>
                <FormHelperText sx={{ ml: 0, pl: 0 }}>{t("label.form.selectInstallationTypeFirst")}</FormHelperText>
            </FormControl>
        );
    }

    return (
        <FormControl>
            <Grid2 container spacing={2}>
                {installationClassifications.filter((c) => c.type === installationType).map((classification, index) => (
                    <Grid2 key={index} item size={{xs: 12, sm:6, lg: 4}}>
                        <InstallationClassificationCard
                            id={`installation_classification_${classification.code}_card`}
                            classification={classification}
                            hideStats={true}
                            selected={selected === classification}
                            onClick={() => onInstallationSelected(classification)}
                        />
                    </Grid2>
                    ))}
            </Grid2>
            {meta.error && meta.touched ? (
                <FormHelperText id="installation_classification_helper_text" error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
    );
}

export default InstallationClassificationSelectSlider;