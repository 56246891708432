import { ArrowBack, Close, Home, ManageAccountsOutlined, WorkspacesOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, Box, Breadcrumbs, ButtonGroup, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormHelperText, IconButton, Input, Link, Skeleton, Stack, Tab, Typography } from "@mui/material";
import BreadcrumbLink from "components/breadcrumbs/BreadcrumbLink";
import NewCommunityMemberConfigureMembersDialog from "components/dialogs/communities/NewCommunityMemberConfigureMembersDialog";
import { CommunitySharingTypeSelect, IndieDSOIDField } from "components/dialogs/communities/NewCommunityDialog";
import CommunityMemberActionMenu from "components/forms/communities/CommunityMemberActionMenu";
import CommunityStatusSelect from "components/forms/communities/CommunityStatusSelect";
import { CommunityTypeSelect } from "components/forms/communities/CommunityTypeSelector";
import EntityFormFields from "components/forms/entities/EntityFormFields";
import IndieButton from "components/forms/IndieButton";
import IndieCancelButton from "components/forms/IndieCancelButton";
import IndieDesktopDatePicker from "components/forms/IndieDesktopDatePickerV2";
import IndieEditButton from "components/forms/IndieEditButton";
import IndieNewItemButton from "components/forms/IndieNewItemButton";
import IndieSaveButton from "components/forms/IndieSaveButton";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import IndieTextField from "components/forms/IndieTextField";
import IndieUpdateStatusButton from "components/forms/IndieUpdateStatusButton";
import IndieCard from "components/IndieCard";
import IndieOutlinedCardSection from "components/IndieOutlinedCardSection";
import { H3 } from "components/Typography";
import { DRF_DATE_FORMAT } from "constants";
import { CommunityContext, CommunityContextProvider } from "contexts/communities/CommunityContext";
import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import ApplicationList from "page-sections/common/ApplicationList";
import { IndieSimpleTable } from "page-sections/common/IndieSimpleTable";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, Route, Routes, useParams, useNavigate } from "react-router-dom";
import { useAgentEntitiesAPIService } from "service/AgentEntitiesAPIService";
import * as Yup from "yup";
import SelectYesNo from "components/SelectYesNo";
import TableSelectFilter from "components/tables/TableSelectFilter";
import { InstallationClassificationTableFilter } from "components/forms/assets/InstallationClassificationFilter";

const CommunityDetail = (props) => {

    const { community_id: id } = useParams();

    return (
        <CommunityContextProvider id={id}>
            <CommunityDetailContent {...props} />
        </CommunityContextProvider>
    );
};

export const CommunityStatusChip = ({ status }) => {
    const { t } = useTranslation();
    const color = status === "active" ? "primary" : "disabled";

    return (
        <Chip label={t(`label.community.status.${status}`)} sx={{ minWidth: 100 }} color={color} />
    )
}

const CommunityDetailContent = () => {

    const { t } = useTranslation();
    const { community, submitting } = useContext(CommunityContext);
    const [currentTab, setCurrentTab] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e, newValue) => {
        setCurrentTab(newValue);
        navigate(newValue, { replace: true });
    };

    useEffect(() => {
        let tab = window.location.pathname.split("/").pop();
        setCurrentTab(tab || "details");
    }, []);

    if (submitting) {
        return <Skeleton variant="rectangular" height={400} />;
    }

    if (!community) {
        return null;
    }

    return (
        <Stack spacing={1}>
            <IndieCard>
                <CardContent>
                    <Stack direction="row" spacing={2}>
                        <IconButton onClick={() => navigate(-1)} size="large" color="primary" aria-label="back">
                            <ArrowBack />
                        </IconButton>
                        <Stack direction="column" spacing={1}>
                            <Breadcrumbs>
                                <BreadcrumbLink value={"label.menu.home"} href="/" icon={<Home sx={{ mr: 0.5 }} fontSize="inherit" />} />
                                <BreadcrumbLink value={"label.menu.communities"} href="/communities/communities" />
                                <BreadcrumbLink value={"label.menu.viewCommunity"} />
                            </Breadcrumbs>
                            <H3>{community.name}</H3>
                        </Stack>
                        <Box sx={{ flexGrow: 1, display: "inline-flex", justifyContent: "flex-end" }}>
                            <CommunityStatusChip status={community.status} />
                        </Box>
                    </Stack>
                </CardContent>
            </IndieCard>
            <IndieCard>
                <CardContent>
                    <TabContext value={currentTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} >
                                <Tab data-testid="tab_details" label={t("label.menu.details")} index={0} value="details"/>
                                <Tab data-testid="tab_consumers" label={t("label.form.consumers")} index={1} value="consumers"/>
                                <Tab data-testid="tab_producers" label={t("label.menu.producers")} index={2} value="producers"/>
                            </TabList>
                        </Box>
                        <TabPanel value={currentTab} sx={{ pt: 2 }}>
                            <Routes>
                                <Route index element={<CommunityDetailsTab />} />
                                <Route path={`details`} element={<CommunityDetailsTab />} />
                                <Route path={`consumers`} element={<CommunityConsumerMembersTab />} />
                                <Route path={`producers`} element={<CommunityProducerMembersTab />} />
                            </Routes>
                        </TabPanel>
                    </TabContext>
                </CardContent>
            </IndieCard>
        </Stack>
    )
};

const CommunityDetailsTab = () => {

    const { t } = useTranslation();
    const [initializing, setInitializing] = useState(true);
    const { community, updateCommunity, submitting, getCommunitiesTariffModels } = useContext(CommunityContext);
    const [updatingCommunity, setUpdatingCommunity] = useState(false);
    const [initialValues, setInitialValues] = useState(community);
    const [editMode, setEditMode] = useState(false);
    const [communityTariffModels, setCommunityTariffModels] = useState([]);
    const [openCommunityStatusDialog, setOpenCommunityStatusDialog] = useState(false);
    const [openUpdateManagingEntityDialog, setOpenUpdateManagingEntityDialog] = useState(false);
    const { setAndShowErrorMessage } = useContext(UserFeedbackContext);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("error.forms.mandatory")),
        dso_id: Yup.number().required(t("error.forms.mandatory")),
        activation_date: Yup.date().required(t("error.forms.mandatory")),
        status: Yup.string().required(t("error.forms.mandatory")),
        type: Yup.string().required(t("error.forms.mandatory")),
        sharing_type: Yup.string().required(t("error.forms.mandatory")),
        managing_entity: Yup.object().required(t("error.forms.mandatory")),
        invoice_network_costs: Yup.boolean().required(t("error.forms.mandatory")),
    });

    const handleCancelUpdateStatusDialog = () => {
        setOpenCommunityStatusDialog(false);
    }

    const handleSuccessUpdateStatusDialog = (status) => {
        setOpenCommunityStatusDialog(false);
    }

    const handleCancelUpdateManagingEntityDialog = () => {
        setOpenUpdateManagingEntityDialog(false);
    }

    const handleSuccessUpdateManagingEntityDialog = (entity, formik) => {
        setOpenUpdateManagingEntityDialog(false);
        formik.setFieldValue('managing_entity', community.managing_entity);
    }

    const handleSubmit = (values) => {
        const _values = _.cloneDeep(values);
        _values['activation_date'] = moment(_values['activation_date']).format(DRF_DATE_FORMAT);
        updateCommunity(_values).then(() => {
            setEditMode(false);
        }).catch((e) => {
            setAndShowErrorMessage(e);
        });
    }

    const activateEditMode = () => {
        setEditMode(true);
    };

    const cancelEditMode = (formik) => {
        setEditMode(false);
        formik.resetForm();
    };
    
    useEffect(() => {
        setUpdatingCommunity(true);
        setInitialValues(community);
        setUpdatingCommunity(false);
        setCommunityTariffModels(getCommunitiesTariffModels().then((response) => {
            setCommunityTariffModels(response.data.map((m) => ({ value: m.id, label: t(`label.communities.tariffModel.title.${m.code}`) })));
            setInitializing(false);
        }).catch((error) => {
            setAndShowErrorMessage(error);
            setCommunityTariffModels([]);
            setInitializing(false);
        }));
    }, [community]);

    if(updatingCommunity || initializing) {
        return (
            <Stack spacing={1}>
                <Skeleton variant="rectangular" height={300}/>
                <Skeleton variant="rectangular" height={300}/>
                <Skeleton variant="rectangular" height={300}/>
            </Stack>
        )
    }
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formik) => (
                    <Form>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={1}>
                                <ButtonGroup variant="outlined">
                                    <IndieEditButton id="button_edit_producer" variant="outlined" disabled={editMode || submitting} onClick={() => activateEditMode()} />
                                    <IndieSaveButton id="button_save_producer" color="primary" disabled={!editMode} variant="outlined" type="submit" size="small" loading={submitting} />
                                    <IndieCancelButton id="button_cancel_edit_producer" color="error" disabled={!editMode} variant="outlined" onClick={() => cancelEditMode(formik)} size="small" />
                                </ButtonGroup>
                                <IndieUpdateStatusButton id="update_status" disabled={submitting} onClick={() => setOpenCommunityStatusDialog(true)} />
                                <IndieButton id="update_managing_entity" startIcon={<ManageAccountsOutlined />} start disabled={submitting} onClick={() => setOpenUpdateManagingEntityDialog(true)} variant="outlined">
                                    {t("label.button.updateManagingEntity")}
                                </IndieButton>
                            </Stack>
                            <Stack spacing={2}>
                                <IndieOutlinedCardSection title="label.title.type">
                                    <Stack spacing={2}>
                                        <CommunityTypeSelect id="type" name="type" disabled={!editMode} />
                                        <CommunitySharingTypeSelect id="sharing_type" name="sharing_type" disabled={!editMode} />
                                    </Stack>
                                </IndieOutlinedCardSection>
                                <IndieOutlinedCardSection title="label.title.invoicing">
                                    <Stack spacing={2}>
                                        <IndieStaticSelect
                                            id="tariff_model"
                                            name="tariff_model"
                                            label={t("label.form.tariffModelType")}
                                            options={communityTariffModels}
                                            sx={{width:250}}
                                            disabled
                                        />
                                        <SelectYesNo id="invoice_network_costs" name="invoice_network_costs" label={t("label.form.invoiceNetworkCosts")} disabled={!editMode} />
                                    </Stack>
                                </IndieOutlinedCardSection>
                                <IndieOutlinedCardSection title="label.title.communityDetails">
                                    <IndieTextField id="name" name="name" label={t("label.form.name")} disabled={!editMode} />
                                    <IndieDesktopDatePicker id="activation_date" name="activation_date" label={t("label.form.activationDate")} disabled={!editMode} />
                                    <IndieDSOIDField id="dso_id" name="dso_id" label={t("label.form.code")} type={formik.values?.type} disabled={!editMode} />
                                </IndieOutlinedCardSection>
                                <IndieOutlinedCardSection title="label.form.managingEntity">
                                    <EntityFormFields prefix={"managing_entity"} disabled />
                                </IndieOutlinedCardSection>
                            </Stack>

                        </Stack>
                        <CommunityUpdateStatusDialog open={openCommunityStatusDialog} handleClose={handleCancelUpdateStatusDialog} handleSuccess={handleSuccessUpdateStatusDialog} />
                        <CommunityUpdateManagingEntityDialog open={openUpdateManagingEntityDialog} handleClose={handleCancelUpdateManagingEntityDialog} handleSuccess={(v) => handleSuccessUpdateManagingEntityDialog(v, formik)} />
                    </Form>
                )}
            </Formik>
        </>
    )
}

const CommunityUpdateStatusDialog = ({ open, handleClose, handleSuccess }) => {

    const { community, updating, updateStatus } = useContext(CommunityContext);
    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);
    const { t } = useTranslation();

    return (

        <Dialog open={open} maxWidth="lg" fullWidth>
            <Formik
                initialValues={{ status: community.satatus }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                    status: Yup.string().required(t("error.forms.mandatory"))
                })}
                onSubmit={(values) => {
                    updateStatus(values.status).then(() => {
                        handleSuccess(values.status);
                        setAndShowSuccessMessage("success.api.save");
                    }).catch((error) => {
                        setAndShowErrorMessage(error);
                    });
                }}
            >
                {() => (
                    <Form>
                        <DialogTitle>
                            <Stack spacing={2} direction="row" alignItems="center">
                                <IconButton onClick={handleClose}>
                                    <Close />
                                </IconButton>
                                <WorkspacesOutlined color="primary" />
                                <Typography variant="subtitle1" color="textPrimary">{t("label.button.updateStatus")}</Typography>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={2}>
                                <Divider />
                                <CommunityStatusSelect id="status" name="status" disabled={updating} />
                            </Stack>

                        </DialogContent>
                        <DialogActions>
                            <IndieCancelButton onClick={handleClose} disabled={updating} />
                            <IndieSaveButton type="submit" loading={updating} disabled={updating} />
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    )
}

const CommunityUpdateManagingEntityDialog = ({ open, handleClose, handleSuccess }) => {

    const agentEntitiesAPI = useAgentEntitiesAPIService();
    const [entities, setEntities] = useState([]);
    const [loadingEntities, setLoadingEntities] = useState(true);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const { community, updating, updateManagingEntity } = useContext(CommunityContext);
    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);
    const { t } = useTranslation();

    const columns = useMemo(() => [
        { field: "entity.name", label: t("label.table.header.companyName"), align: "left" },
        { field: "entity.fiscal_number", label: t("label.table.header.fiscalNumber"), align: "left" },
        { field: "resp_party.name", label: t("label.table.header.respName"), align: "left" },
    ], []);

    const fetchEntities = () => {
        setLoadingEntities(true);
        agentEntitiesAPI.fetch([{ id: "agent_type", value: "egac" }], 100, 1, false, (response) => {
            setEntities(response.data.results.filter((e) => e.entity.id !== community.managing_entity.id));
            setLoadingEntities(false);
        }, (error) => {
            console.error(error);
            setAndShowErrorMessage(error);
            setLoadingEntities(false);
        });
    }

    const handleRowClick = (row, formik) => {
        setSelectedEntity(row);
        formik.setFieldValue("managing_entity", row.id);

    };

    useEffect(() => {
        fetchEntities();
        return () => {
            setEntities([]);
        }
    }, [open]);

    return (

        <Dialog open={open} maxWidth="lg" fullWidth>
            <Formik
                initialValues={{ status: community.satatus }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                    managing_entity: Yup.string().required(t("error.forms.mandatory"))
                })}
                onSubmit={(values) => {
                    updateManagingEntity(values.managing_entity).then(() => {
                        handleSuccess(values.managing_entity);
                        setAndShowSuccessMessage("success.api.save");
                    }).catch((error) => {
                        setAndShowErrorMessage(error);
                    });
                }}
            >
                {(formik) => (
                    <Form>
                        <DialogTitle>
                            <Stack spacing={2} direction="row" alignItems="center">
                                <IconButton onClick={handleClose}>
                                    <Close />
                                </IconButton>
                                <ManageAccountsOutlined color="primary" />
                                <Typography variant="subtitle1" color="textPrimary">{t("label.button.updateManagingEntity")}</Typography>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={2}>
                                <Divider />
                                {entities && entities.length > 0 && (
                                    <IndieSimpleTable data={entities} columns={columns} loading={loadingEntities} onRowClick={(row) => {
                                        handleRowClick(row, formik);
                                    }} selected={selectedEntity} outlined/>
                                )}
                                {(!entities || entities.length) === 0 && (
                                    <Alert width={"md"} severity="warning" variant="filled">{t("error.forms.newCommunity.noEGACEntities")}</Alert>
                                )}
                                {formik.errors.managing_entity && <FormHelperText id="managing_entity_helper_text" error>{formik.errors.managing_entity}</FormHelperText>}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <IndieCancelButton onClick={handleClose} disabled={updating} />
                            <IndieSaveButton type="submit" loading={updating} disabled={updating} />
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    )
}


const CommunityConsumerMembersTab = () => {

    const { community, getConsumerMembers } = useContext(CommunityContext);
    const { t } = useTranslation();
    const [openNewCommunityMemberDialog, setOpenNewCommunityMemberDialog] = useState(false);

    const columns = useMemo(() => [
        {
            accessor: 'id',
            Header: "",
            Filter: () => null,
            width: 50,
            Cell: ({ row }) => (
                <CommunityMemberActionMenu member={row.original} />
            )
        },
        {
            accessor: 'ext_member_id',
            Header: t("label.table.header.dgegID"),
            align: "center"
        },
        {
            accessor: 'physical_unit.classification',
            Header: t("label.table.header.puClassification"),
            Cell: ({ value }) => <Typography fontSize={13}>{value ? t(`label.physicalUnitClassification.${value}`) : null}</Typography>,
            filterComponent: InstallationClassificationTableFilter
        },
        {
            accessor: 'consumer.name',
            Header: t("label.table.header.name"),
            Cell: ({ row }) => <Link component={RouterLink} to={`/communities/clients/${row.original.consumer?.id}`}>{row.original.consumer?.name}</Link>
        },
        {
            accessor: 'consumer.fiscal_number',
            Header: t("label.table.header.fiscalNumber"),
        },
        {
            accessor: 'physical_unit.consumption_deliverypoint',
            Header: t("label.table.header.consumptionDeliverypoint"),
            Cell: ({ row }) => {
                const { physical_unit } = row.original;
                const physicalUnitId = physical_unit?.id;
                const deliverypoint = physical_unit?.consumption_deliverypoint;
        
                if (!physicalUnitId || !deliverypoint) {
                    return <span>—</span>;
                }
        
                return (
                    <Link component={RouterLink} to={`/physical-units/${physicalUnitId}`}>
                        {deliverypoint}
                    </Link>
                );
            }
        },
        {
            accessor: 'consumer.address',
            Header: t("label.table.header.address"),
        },
        {
            accessor: 'consumer.zip_code',
            Header: t("label.table.header.zipCode"),
        },
        {
            accessor: 'consumer.locality',
            Header: t("label.table.header.locality"),
        },
        {
            accessor: 'status',
            Header: t("label.form.contractStatus"),
            Cell: ({ value }) => value ? t(`label.status.member.${value}`) : null,
            filterComponent: StatusFilter,
        },
        {
            accessor: 'consumer.username',
            Header: t("label.table.header.hasIndieAccount"),
            Cell: ({ value }) => value ? t("label.form.yes") : t("label.form.no"),
            Filter: () => <Input sx={{ visibility: "hidden" }} />,
            align: "center"
        },
    ], [t]);

    return (
        <Fragment>
            <ApplicationList
                id="consumer_members"
                application={`communities/${community.id}/consumers`}
                dataFetcher={getConsumerMembers}
                showAppBar={true}
                columns={columns}
                outlined
                useSorting={true}
                autoLoad
                appBar={[
                    <IndieNewItemButton id="configure_member" key="configure_member" variant="contained" label={t("label.button.configureMember")} onClick={() => setOpenNewCommunityMemberDialog(true)} />
                ]}
            />
            <NewCommunityMemberConfigureMembersDialog open={openNewCommunityMemberDialog} onClose={() => setOpenNewCommunityMemberDialog(false)} />
        </Fragment>
    )
}


const CommunityProducerMembersTab = () => {

    const { getProducerMembers } = useContext(CommunityContext);
    const { t } = useTranslation();
    const [openNewCommunityMemberDialog, setOpenNewCommunityMemberDialog] = useState(false);

    const columns = useMemo(() => [
        {
            accessor: 'id',
            Header: "",
            Filter: () => null,
            width: 50,
            Cell: ({ row }) => (
                <CommunityMemberActionMenu member={row.original} />
            )
        },
        {
            accessor: 'ext_member_id',
            Header: t("label.table.header.dgegID"),
            align: "center"
        },
        {
            accessor: 'physical_unit.classification',
            Header: t("label.table.header.puClassification"),
            Cell: ({ value }) => <Typography fontSize={13}>{t(`label.physicalUnitClassification.${value}`)}</Typography>,
            filterComponent: InstallationClassificationTableFilter
        },
        {
            accessor: 'producer',
            Header: t("label.table.header.name"),
            Cell: ({ value }) => <Link component={RouterLink} to={`/communities/clients/${value?.id}`}>{value?.name}</Link>
        },
        {
            accessor: 'producer.fiscal_number',
            Header: t("label.table.header.fiscalNumber"),
        },
        {
            accessor: 'producer.receipt_tax_regime.description',
            Header: t("label.table.header.fiscalRegime"),
        },
        {
            accessor: 'physical_unit.consumption_deliverypoint',
            Header: t("label.table.header.consumptionDeliverypoint"),
            Cell: ({ row }) => {
                const { physical_unit } = row.original;
                const physicalUnitId = physical_unit?.id;
                const deliverypoint = physical_unit?.consumption_deliverypoint;
        
                if (!physicalUnitId || !deliverypoint) {
                    return <span>—</span>;
                }
        
                return (
                    <Link component={RouterLink} to={`/physical-units/${physicalUnitId}`}>
                        {deliverypoint}
                    </Link>
                );
            }
        },
        {
            accessor: 'producer.address',
            Header: t("label.table.header.address"),
        },
        {
            accessor: 'producer.zip_code',
            Header: t("label.table.header.zipCode"),
        },
        {
            accessor: 'producer.locality',
            Header: t("label.table.header.locality"),
        },
        {
            accessor: 'status',
            Header: t("label.form.contractStatus"),
            Cell: ({ value }) => value ? t(`label.status.member.${value}`) : null,
            filterComponent: StatusFilter,
        },
        {
            accessor: 'oferee_party.username',
            Header: t("label.table.header.hasIndieAccount"),
            Cell: ({ value }) => value ? t("label.form.yes") : t("label.form.no"),
            Filter: () => <Input sx={{ visibility: "hidden" }} />
        },
    ], [t]);

    return (
        <Fragment>
            <ApplicationList
                id="producer_members"
                application={`clients`}
                dataFetcher={getProducerMembers}
                showAppBar={true}
                columns={columns}
                outlined
                autoLoad
                appBar={[
                    <IndieNewItemButton id="configure_member" key="configure_member" label={t("label.button.configureMember")} variant="contained" onClick={() => setOpenNewCommunityMemberDialog(true)} />
                ]}
            />
            <NewCommunityMemberConfigureMembersDialog open={openNewCommunityMemberDialog} onClose={() => setOpenNewCommunityMemberDialog(false)} />
        </Fragment>
    )
}

const StatusFilter = (params) => {
    const { t } = useTranslation();
    const statusOptionsLoader = () => {
        return Promise.resolve([
            { value: "", label: "Todos" },
            { value: "prerecord", label: t(`label.status.member.prerecord`) },
            { value: "active", label: t(`label.status.member.active`) },
            { value: "registered", label: t(`label.status.member.registered`) },
            { value: "suspended", label: t(`label.status.member.suspended`) },
            { value: "inactive", label: t(`label.status.member.inactive`) },
        ]);
    };

    return (
        <TableSelectFilter optionsLoader={statusOptionsLoader} {...params} />
    );
};

export default CommunityDetail;