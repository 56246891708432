import { AddCard, Close, Home } from "@mui/icons-material";
import { Alert, Box, Breadcrumbs, CardContent, Dialog, DialogContent, DialogTitle, Divider, Grid2, IconButton, Skeleton, Stack, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import BreadcrumbLink from "components/breadcrumbs/BreadcrumbLink";
import ContractDurationSelect from "components/contracts/ContractDurationSelect";
import EnergyTransactionsSelect, { EnergyTransactionsSelectFilter } from "components/contracts/tariffs/EnergyTransactionsSelect";
import TariffFeeForm from "components/contracts/tariffs/TariffFeeForm";
import TariffPlansSelect from "components/contracts/tariffs/TariffPlansSelect";
import TariffProductsSelect from "components/contracts/tariffs/TariffProductsSelect";
import PlanTypeCardSelect from "components/contracts/tariffs/PlanTypeCardSelect";
import { IndieLabelAndValue } from "components/dialogs/communities/NewCommunityDialog";
import IndieBackButton from "components/forms/IndieBackButton";
import IndieContinueButton from "components/forms/IndieContinueButton";
import IndieNewItemButton from "components/forms/IndieNewItemButton";
import IndieNumberField from "components/forms/IndieNumberField";
import IndieSaveButton from "components/forms/IndieSaveButton";
import IndieTextField from "components/forms/IndieTextField";
import IndieCard from "components/IndieCard";
import { H3, Small } from "components/Typography";
import { TariffsContext, TariffsContextProvider } from "contexts/tariffs/TariffsContext";
import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { Form, Formik } from "formik";
import _ from "lodash";
import ApplicationList, { SelectOptionsFilter } from "page-sections/common/ApplicationList";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTariffsAPIService } from "service/core/TariffsAPIService";
import * as Yup from "yup";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import useAuth from "hooks/useAuth";

export const BusinessTypeSelect = (props) => {

    const { t } = useTranslation();
    const { hasPermissionGroup } = useAuth();
    const { setBusinessType } = useContext(TariffsContext);
    const options = [];
    if (hasPermissionGroup(["INDIE_ADMIN", "ENERGY_AGENT_AGGRTN_USER"])) {
        options.push({ value: "aggregation", label: t("label.businessType.aggregation") });
    }

    if (hasPermissionGroup(["INDIE_ADMIN", "ENERGY_AGENT_EGAC_USER"])) {
        options.push({ value: "communities", label: t("label.businessType.communities") });
    }

    return (
        <IndieStaticSelect label={t("label.form.businessType")} options={options} {...props} sx={{ width: 250 }} onChange={(e) => setBusinessType(e.target.value)} />
    )
};

export const PlanTypeFilter = ({ column, fetchData, state }) => {

    const { t } = useTranslation();
    const { getPlanTypes } = useContext(TariffsContext);
    const [planTypes, setPlanTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPlanTypes().then(
            (response) => {
                setPlanTypes(
                    response.map((type) => (
                        {id: type.id, label: t(`label.tariffPlanType.${type.code}`)}
                    ))
                )
            }
        ).catch((error) => console.error(error)).finally(() => setLoading(false));
    }, []);

    if(loading) return <Skeleton variant="text" height={40} />

    return <SelectOptionsFilter id="plan_type" column={column} fetchData={fetchData} state={state} options={planTypes} />
}

export const ProductFilter = ({ column, fetchData, state }) => {

    const { t } = useTranslation();
    const { getProducts } = useContext(TariffsContext);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProducts().then(
            (response) => {
                setProducts(
                    response.map((product) => (
                        {id: product.id, label: t(`label.product.${product.code}`)}
                    ))
                )
            }
        ).catch((error) => console.error(error)).finally(() => setLoading(false));
    }, []);

    if(loading) return <Skeleton variant="text" height={40} />

    return <SelectOptionsFilter id="product" column={column} fetchData={fetchData} state={state} options={products} />
}

export const PlanFilter = ({ column, fetchData, state }) => {

    const { t } = useTranslation();
    const { getProductPlans } = useContext(TariffsContext);
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProductPlans().then(
            (response) => {
                setPlans(
                    response.map((plan) => (
                        {id: plan.id, label: t(`${plan.description}`)}
                    ))
                )
            }
        ).catch((error) => console.error(error)).finally(() => setLoading(false));
    }, []);

    if(loading) return <Skeleton variant="text" height={40} />

    return <SelectOptionsFilter id="plan" column={column} fetchData={fetchData} state={state} options={plans} />
}

const TariffModellListPage = () => {

    const { t } = useTranslation();
    const [refreshListTrigger, setRefreshListTrigger] = useState(0);
    const [openNewTariffModelDialog, setOpenNewTariffModelDialog] = useState(false);
    const api = useTariffsAPIService();
    const columns = useMemo(() => [
        {
            Header: t("label.table.header.name"),
            accessor: "name",
            columnType: "link",
            minWidth: 200,
        },
        {
            Header: t("label.table.header.planType"),
            accessor: "plan_type.id",
            Cell: ({ row }) => {
                return <Typography variant="body2">{t(`label.tariffPlanType.${row.original.plan_type.code}`)}</Typography>
            },
            minWidth: 200,
            filterComponent: ({ column, fetchData, state }) => <PlanTypeFilter column={column} fetchData={fetchData} state={state} />,
        },
        {
            Header: t("label.table.header.product"),
            accessor: "product.id",
            Cell: ({ row }) => {
                return <Typography variant="body2">{t(`label.product.${row.original.product.code}`)}</Typography>
            },
            minWidth: 200,
            filterComponent: ({ column, fetchData, state }) => <ProductFilter column={column} fetchData={fetchData} state={state} />,
        },
        {
            Header: t("label.table.header.energyTransaction"),
            accessor: "energy_transaction.id",
            Cell: ({ row }) => (
                row.original.energy_transaction && row.original.energy_transaction.code ? <Small>{t(`label.energyTransaction.${row.original.energy_transaction.code}`)}</Small> : null
            ),
            filterComponent: EnergyTransactionsSelectFilter
        },
        {
            Header: t("label.table.header.plan"),
            accessor: "plan.id",
            minWidth: 200,
            Cell: ({ row }) => {
                return <Typography variant="body2">{row.original.plan.description}</Typography>
            },
            filterComponent: ({ column, fetchData, state }) => <PlanFilter column={column} fetchData={fetchData} state={state} />,
        },

    ], []);

    return (
        <Grid2 container spacing={2}>
            <Grid2 item size={{ xs: 12 }}>
                <IndieCard>
                    <CardContent>
                        <Stack direction="column" spacing={1}>
                            <Breadcrumbs>
                                <BreadcrumbLink value={"label.menu.home"} href="/" icon={<Home sx={{ mr: 0.5 }} fontSize="inherit" />} />
                                <BreadcrumbLink value={"label.title.tariffModels"} />
                            </Breadcrumbs>
                            <H3>{t("label.title.tariffModels")}</H3>
                        </Stack>
                    </CardContent>
                </IndieCard>
            </Grid2>
            <Grid2 item size={{ xs: 12 }}>
                <TariffsContextProvider>
                    <IndieCard>
                        <CardContent>
                            <ApplicationList
                                application={"tariff-models"}
                                columns={columns}
                                showAppBar
                                dataFetcher={api.getTariffModels}
                                refresh={refreshListTrigger}
                                outlined
                                appBar={[
                                    <IndieNewItemButton id="new_tariff_model" onClick={() => setOpenNewTariffModelDialog(true)} variant="contained" />
                                ]}
                            />
                        </CardContent>
                    </IndieCard>


                    <NewTariffModelDialog open={openNewTariffModelDialog} handleClose={() => {
                        setOpenNewTariffModelDialog(false);
                        setRefreshListTrigger(refreshListTrigger + 1);
                    }} />
                </TariffsContextProvider>
            </Grid2>
        </Grid2>
    )
}

const NewTariffModelDialog = ({ open, handleClose }) => {

    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const { submitTariffModel } = useContext(TariffsContext);
    const { setAndShowSuccessMessage, setAndShowErrorMessage } = useContext(UserFeedbackContext);

    const initialValues = {
        name: "",
        plan_type: "",
        duration: "",
        termination_lead_days: "",
        product: "",
        energy_transaction: "",
        power_fee: "",
        energy_fee: "",
        business_type: "",
        isSellEnergyProduct: false,
    };
    const stepNameValidationSchema = Yup.object().shape({
        name: Yup.string().required(t("error.forms.mandatory")),
        duration: Yup.string().required(t("error.forms.mandatory")),
        termination_lead_days: Yup.number().required(t("error.forms.mandatory")),
        business_type: Yup.string().required(t("error.forms.mandatory"))
    });

    const stepPlanTypeValidationSchema = Yup.object().shape({
        plan_type: Yup.object().required(t("error.forms.mandatory"))
    });

    const stepTransactionTypeValidationSchema = Yup.object().shape({
        product: Yup.string().required(t("error.forms.mandatory")),
        energy_transaction: Yup.string().required(t("error.forms.mandatory"))
    });

    const stepTariffTypeValidationSchema = Yup.object().shape({
        plan: Yup.string().required(t("error.forms.mandatory")),
        fixed_price_tariff: Yup.string().when("plan_object", (plan_object, schema) => {
            if (plan_object.use_energy_price) {
                return schema.shape(Yup.number().required(t("error.forms.mandatory")));
            }
            return schema;
        }),
    });

    const stepFeesValidationSchema = Yup.object().shape({
        power_fee: Yup.string().when("isSellEnergyProduct", {
            is: false,
            then: (schema) => schema.required(t("error.forms.mandatory")),
            otherwise: (schema) => schema.notRequired(),
        }),
        power_fee_value: Yup.number().when("isSellEnergyProduct", {
            is: false,
            then: (schema) => schema.required(t("error.forms.mandatory")),
            otherwise: (schema) => schema.notRequired(),
        }),
        energy_fee: Yup.string().when(["plan_object", "isSellEnergyProduct"], ([plan_object, isSellEnergyProduct], schema) => {
            if (!isSellEnergyProduct) {
                if (plan_object?.use_commission_prcnt || plan_object?.use_commission_total) {
                    return schema.required(t("error.forms.mandatory"));
                }
            }
            return schema.notRequired();
        }),
        energy_fee_value: Yup.number().when(["plan_object", "isSellEnergyProduct"], ([plan_object, isSellEnergyProduct], schema) => {
            if (!isSellEnergyProduct) {
                if (plan_object?.use_commission_prcnt || plan_object?.use_commission_total) {
                    return schema.required(t("error.forms.mandatory"));
                }
            }
            return schema.notRequired();
        }),
    });

    const validationSchemas = useMemo(() => [
        stepNameValidationSchema,
        stepPlanTypeValidationSchema,
        stepTransactionTypeValidationSchema,
        stepTariffTypeValidationSchema,
        stepFeesValidationSchema,
        Yup.object().shape({}),
    ], []);

    const handleSubmit = (values) => {
        if (activeStep < 5) {
            setActiveStep(activeStep + 1);
        } else {
            console.info("Saving: ", values);
            // remove the <name>_objects from the values
            let _values = _.cloneDeep(values);
            delete _values.product_object;
            delete _values.energy_transaction_object;
            delete _values.plan_object;
            delete _values.power_fee.fee_object;
            delete _values.energy_fee.fee_object;
            delete _values.duration_object;
            delete _values.isSellEnergyProduct;

            _values.plan_type = _values.plan_type.id;

            setSubmitting(true);
            submitTariffModel(_values).then(() => {
                handleClose();
                setAndShowSuccessMessage(t("success.api.create"));
            }).catch((error) => {
                setAndShowErrorMessage(error);
            }).finally(() => {
                setSubmitting(false);
            });
        }

    }

    const onChangePlanType = (formik) => {
        formik.setFieldValue("product", "");
        formik.setFieldValue("energy_transaction", "");
        formik.setFieldValue("plan", "");
        formik.setFieldValue("fixed_price_tariff", "");
        formik.setFieldValue("power_fee", "");
        formik.setFieldValue("energy_fee", "");
    }

    const onChangeProduct = (formik) => {
        formik.setFieldValue("energy_transaction", "");
        formik.setFieldValue("plan", "");
        formik.setFieldValue("fixed_price_tariff", "");
        formik.setFieldValue("power_fee", "");
        formik.setFieldValue("energy_fee", "");
    }

    const onChangeEnergyTransaction = (formik) => {
        const isSellProduct = formik.values.product_object?.code === "VE";
        formik.setFieldValue("isSellEnergyProduct", isSellProduct);
        formik.setFieldValue("plan", "");
        formik.setFieldValue("fixed_price_tariff", "");
        formik.setFieldValue("power_fee", "");
        formik.setFieldValue("energy_fee", "");
    }

    const onChangePlan = (formik) => {
        formik.setFieldValue("power_fee", "");
        formik.setFieldValue("energy_fee", "");
        formik.setFieldValue("fixed_price_tariff", "");
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    }

    useEffect(() => {
        if (!open) {
            setActiveStep(0);
        }
    }, [open]);
    return (
        <Dialog open={open} onClose={() => null} fullScreen>
            <DialogTitle sx={{ p: 2 }}>
                <Stack spacing={2} direction="row" alignItems="center">
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                    <AddCard color="primary" />
                    <Typography variant="subtitle1" color="textPrimary">{t("label.title.newTariffModel")}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider sx={{ mb: 2 }} />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemas[activeStep]}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step key={0}>
                                    <StepLabel>{t("label.step.newTariffModel.name")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <IndieTextField id="name" name="name" label={t("label.form.name")} maxLength={200} />
                                            <ContractDurationSelect id="duration" name="duration" label={t("label.form.duration")} />
                                            <IndieNumberField
                                                id="termination_lead_days"
                                                name="termination_lead_days"
                                                label={t("label.form.contractTermntLeadDays")}
                                                sx={{ width: 200 }}
                                            />
                                            <BusinessTypeSelect id="business_type" name="business_type" />
                                            <Stack direction="row" spacing={2}>
                                                <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={1}>
                                    <StepLabel>{t("label.step.newTariffModel.planType")}</StepLabel>
                                    <StepContent>
                                        <Box maxWidth="md">
                                            <Stack spacing={2}>
                                                <Typography variant="subtitle2">{t("label.step.newTariffModel.tariffPlanType.subtitle")}</Typography>
                                                <PlanTypeCardSelect name="plan_type" onChange={() => onChangePlanType(formik)} businessType={formik.values.business_type} />
                                                <Stack direction="row" spacing={2}>
                                                    <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                    <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </Box>

                                    </StepContent>
                                </Step>
                                <Step key={2}>
                                    <StepLabel>{t("label.step.newTariffModel.transactionType")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={4}>
                                            <Stack spacing={2}>
                                                <TariffProductsSelect
                                                    id={`product`}
                                                    name={`product`}
                                                    planType={formik.values.plan_type}
                                                    onChange={() => onChangeProduct(formik)}
                                                />
                                                <EnergyTransactionsSelect
                                                    id={`energy_transaction`}
                                                    name={`energy_transaction`}
                                                    planType={formik.values.plan_type.id}
                                                    product={formik.values.product}
                                                    businessType={formik.values.business_type}
                                                    onChange={() => onChangeEnergyTransaction(formik)}
                                                    disabled={!formik.values.product}
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={3}>
                                    <StepLabel>{t("label.step.newTariffModel.tariffType")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={4}>
                                            <Typography variant="subtitle2">{t("label.step.newTariffModel.tariffType.subtitle")}</Typography>
                                            <TariffPlansSelect id="plan" name="plan" product={formik.values.product} onChange={() => onChangePlan(formik)} />
                                            <IndieNumberField id="fixed_price_tariff" name="fixed_price_tariff" label={t("label.form.fixedPriceTariff")} disabled={!formik.values.plan_object?.use_energy_price} sx={{ width: 250 }} />
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={4}>
                                    <StepLabel>{t("label.step.newTariffModel.fees")}</StepLabel>
                                    <StepContent>
                                        <Box maxWidth="md">
                                            <Stack spacing={4}>
                                                <Typography variant="subtitle2">{t("label.step.newTariffModel.fees.subtitle")}</Typography>
                                                {!formik.values.isSellEnergyProduct && <Grid2 container spacing={2}>
                                                    <Grid2 item size={{ xs: 12, md: 6 }}>
                                                        <TariffFeeForm name="power_fee" plan={formik.values.plan} type="power" />
                                                    </Grid2>
                                                    <Grid2 item size={{ xs: 12, md: 6 }}>
                                                        <TariffFeeForm name="energy_fee" plan={formik.values.plan} type="energy" />
                                                    </Grid2>
                                                </Grid2>}
                                                {formik.values.isSellEnergyProduct && (
                                                    <Alert severity="info">{t("label.text.productTaxesNotRequired")}</Alert>
                                                )}
                                                <Stack direction="row" spacing={2}>
                                                    <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                    <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </StepContent>
                                </Step>

                                <Step key={5}>
                                    <StepLabel>{t("label.step.newTariffModel.confirmationStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={4}>
                                            <Typography variant="subtitle2">{t("label.step.newTariffModel.confirmationStep.subtitle")}</Typography>
                                            <Stack spacing={4}>
                                                <Stack spacing={1}>
                                                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newTariffModel.name")}</Typography>
                                                    <Divider />
                                                    <IndieLabelAndValue label="label.form.name" value={formik.values.name} />
                                                    <IndieLabelAndValue label="label.form.duration" value={formik.values.duration} />
                                                    <IndieLabelAndValue label="label.form.contractTermntLeadDays" value={formik.values.termination_lead_days} />
                                                </Stack>
                                                <Stack spacing={1}>
                                                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newTariffModel.planType")}</Typography>
                                                    <Divider />
                                                    <IndieLabelAndValue label="label.step.newTariffModel.planType" value={t(`label.tariffPlanType.${formik.values.plan_type?.code}`)} />
                                                </Stack>
                                                <Stack spacing={1}>
                                                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newTariffModel.transactionType")}</Typography>
                                                    <Divider />
                                                    <IndieLabelAndValue label="label.form.product" value={formik.values.product_object?.label} />
                                                    <IndieLabelAndValue label="label.form.energyTransaction" value={formik.values.energy_transaction_object?.label} />
                                                </Stack>
                                                <Stack spacing={1}>
                                                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newTariffModel.tariffType")}</Typography>
                                                    <Divider />
                                                    <IndieLabelAndValue label="label.form.plan" value={formik.values.plan_object?.label} />
                                                    <IndieLabelAndValue label="label.form.fixedPriceTariff" value={formik.values.fixed_price_tariff} />
                                                </Stack>
                                                {!formik.values.isSellEnergyProduct && <Stack spacing={1}>
                                                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newTariffModel.fees")}</Typography>
                                                    <Divider />
                                                    <IndieLabelAndValue label="label.form.powerFee" value={formik.values.power_fee_object?.label} />
                                                    <IndieLabelAndValue label="label.form.value" value={formik.values.power_fee_value} />
                                                    <IndieLabelAndValue label="label.form.energyFee" value={formik.values.energy_fee_object?.label} />
                                                    <IndieLabelAndValue label="label.form.value" value={formik.values.energy_fee_value} />
                                                </Stack>}
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} disabled={submitting} />
                                                <IndieSaveButton id="save_button" variant="contained" type="submit" loading={submitting} disabled={submitting} />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                            </Stepper>
                            {/**<pre>{JSON.stringify(formik.errors)}</pre>*/}
                            {/**<pre>{JSON.stringify(formik.values)}</pre>*/}
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default TariffModellListPage;