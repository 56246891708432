import { useTranslation } from "react-i18next";
import IndieStaticSelect from "../IndieStaticSelect";
import { useMemo } from "react";
import { useField } from "formik";
import { FormControl, FormHelperText } from "@mui/material";

const PhysicalUnitClassificationSelect = ({installationType, ...props}) => {

    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props);

    const classificationMapping = {
        ic: ["ic", "ic_strg", "ic_prod", "ic_prod_strg"],
        ip: ["ip", "ip_strg"],
        ia: ["ia"]
    };

    const allClassifications = useMemo(() => [
        {value: "ic", label: t("label.installationClassification.consumptionInstallationNoProduction")},
        {value: "ic_prod", label: t("label.installationClassification.consumptionInstallationWithProduction")},
        {value: "ic_prod_strg", label: t("label.installationClassification.consumptionInstallationWithProductionAndStorage")},
        {value: "ic_strg", label: t("label.installationClassification.consumptionInstallationNoProductionWithStorage")},
        {value: "ip", label: t("label.installationClassification.productionInstallation")},
        {value: "ip_strg", label: t("label.installationClassification.productionInstallationWithStorage")},
        {value: "ia", label: t("label.installationClassification.storageInstallation")}
    ], []);

    const filteredOptions = useMemo(() => {
        if (!installationType) return [];
        return allClassifications.filter(option => classificationMapping[installationType]?.includes(option.value));
    }, [installationType, allClassifications]);

    const handleChange = (event) => {
        helpers.setValue(event.target.value);
    };

    return (
        <IndieStaticSelect
            {...props}
            options={filteredOptions}
            label={t("label.form.classification")}
            sx={{width: 350}}
            onChange={handleChange}
            disabled={!installationType || props.disabled}
        />
    )
}

export default PhysicalUnitClassificationSelect;