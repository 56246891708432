/**
 * A generic table component with filters.
 * 
 * This component is a generic table component that can be used to display data in a table format.
 * It supports filtering, sorting, and pagination. The table can be used to display data from any source, such as an API or a local data source.
 * The table can be customized with custom columns and filters.
 * 
 * The columns array supports several attributes, namely:
 * columnType: The type of the column. This can be "link" or "text". If the
 *             columnType is "link", the column will be rendered as a link with
 *             a link to the application containing the ID and the application received.
 * 
 * filterType: The type of the filter. This can be "text" or "date".
 * filterComponent: The name of the component to be used for filtering. This can be used
 *                  to provide a custom filter component for a column.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.application - The application name.
 * @param {Array} props.columns - The column definitions for the table.
 * @param {Function} props.dataFetcher - The function to fetch data for the table. Must have the following signature: (filters, pageSize, page, ordering, successCallback, errorCallback) => {}.
 * @param {Function} props.rowOnClick - The function to handle row click events.
 * @param {boolean} props.useSorting - Whether to enable sorting for the table.
 * @returns {JSX.Element} The rendered GenericTableWithFilters component.
 */
import { FilterAlt, FilterAltOff, GridViewRounded, Inbox, MapRounded, OpenInNew, RefreshRounded, ViewListRounded } from "@mui/icons-material";
import { Autocomplete, Box, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, Link, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { DateTableFilter } from "components/DateTableFilters";
import IndieCard from "components/IndieCard";
import Scrollbar from "components/ScrollBar";
import IndieCancelButton from "components/forms/IndieCancelButton";
import IndieDesktopDatePicker from "components/forms/IndieDesktopDatePickerV2";
import IndieSearchButton from "components/forms/IndieSearchButton";
import { Form, Formik } from "formik";
import { toInteger } from "lodash";
import moment from "moment";
import { BodyTableCell, HeadTableCell } from "page-sections/accounts/account/common/StyledComponents";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useAsyncDebounce, useFilters, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { DATETIME_FORMAT, DRF_DATE_FORMAT } from "../../constants";
import IndieApplicationListMap from "./IndieApplicationListMap";

export const useApplicationList = (application) => {

    const getApplicationFilters = () => {
        let _filters = JSON.parse(sessionStorage.getItem(`${application}:list:filters`)) || [];
        let _additionalFilters = JSON.parse(sessionStorage.getItem(`${application}:list:additionalFilters`)) || [];
        let _advancedSearchFilters = JSON.parse(sessionStorage.getItem(`${application}:list:advancedSearchFilters`)) || [];
        return [..._filters, ..._additionalFilters, ..._advancedSearchFilters];
    };

    return {
        getApplicationFilters: getApplicationFilters
    }
}

const ApplicationList = forwardRef(({
    application, columns, dataFetcher, rowOnClick, rowOnClick2, useSorting,
    refresh, additionalFilters = [], advancedFilterList, showAdvancedSearch, showAppBar, appBar,
    onDataChange, dataPreparation, cardComponent: CardComponent,
    showListView = true, showGridView = false, showMapView = false,
    itemInfoWindow, itemToMarker, selectable, multiple, autoLoad = false,
    ...props}, ref) => {

    const { t } = useTranslation();

    const theme = useTheme();
    // View Type Controls
    const [viewType, setViewType] = useState("list");

    // List Control State
    const [ordering, setOrdering] = useState(null);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortedDir, setSortedDir] = useState(null);
    const [listFilters, setListFilters] = useState([]);
    const [advancedSearchFilters, setAdvancedSearchFilters] = useState([]);
    const [currentListPage, setCurrentListPage] = useState(0);
    const [totalListPages, setTotalListPages] = useState(0);
    const [totalListItems, setTotalListItems] = useState(0);
    const [hasSearch, setHasSearch] = useState(false);
    // Control Loading Table data state
    const [isLoading, setIsLoading] = useState(false);
    const [listItems, setListItems] = useState([]);
    const [listSize, setListSize] = useState(props.listSize || 10);
    // Control Page Setup
    const [isSettingUp, setIsSettingUp] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    
    const defaultColumn = useMemo(
        () => ({
            Cell: ({ row, column, value, ...props }) => {
                if (column.columnType === "link") {
                    return <Link
                        id={`table_row_${row.index}_link`}
                        component={RouterLink}
                        fontSize={13} noWrap={true}
                        to={{ pathname: `/${application}/${row.original.id}/${column.linkPath || ''}` }}
                        sx={{
                            zIndex: (theme) => theme.zIndex.drawer - 1,
                            cursor: "pointer"
                        }}>
                        {value && value.length > 50 ? value.substring(0, 47) + "..." : value}
                    </Link>
                } else if (column.columnType === "openinnew") {
                    return <Link
                        id={`table_row_${row.id}_link`}
                        component={RouterLink}
                        fontSize={13} noWrap={true}
                        to={{ pathname: `/${application}/${row.original.id}/${column.linkPath || ''}` }}
                        sx={{
                            zIndex: (theme) => theme.zIndex.drawer - 1,
                            cursor: "pointer"
                        }}>
                        {value && value.length > 50 ? value.substring(0, 47) + "..." : value}
                        <OpenInNew fontSize="small" />
                    </Link>
                } else if (column.columnType === "boolean") {
                    return <Typography fontSize={13} noWrap={true}>{value ? t("label.form.yes") : (value === false ? t("label.form.no") : "")}</Typography>
                } else if (column.columnType === "date" && value !== null) {
                    return <Typography fontSize={13} noWrap={true}>{moment(value).format(DRF_DATE_FORMAT)}</Typography>
                } else if (column.columnType === "datetime" && value !== null) {
                    return <Typography fontSize={13} noWrap={true}>{moment(value).format(DATETIME_FORMAT)}</Typography>
                } else {
                    return <Typography fontSize={13} noWrap={true}>{value}</Typography>
                }
            }
            ,
            Filter: ({ column, state }) => {
                if (column.filterType === "date") {
                    return <DateFilter column={column} fetchData={debouncedFetchListData} setListPage={setCurrentListPage} state={state} />
                } else if (column.filterType === "boolean") {
                    return <SelectYesNoFilter column={column} fetchData={debouncedFetchListData} state={state} />
                } else if (column.filterType === "none") {
                    return <DefaultFilter column={column} fetchData={debouncedFetchListData} state={state} disabled sx={{ visibility: "hidden" }} />
                } else if (column.filterComponent) {
                    return <column.filterComponent column={column} fetchData={debouncedFetchListData} setListPage={setCurrentListPage} state={state} />
                } else {
                    return <DefaultFilter column={column} fetchData={debouncedFetchListData} state={state} />
                }
            },
        }),
        []
    );

    const clearData = () => {
        setListItems([]);
    };

    const refreshData = () => {
        fetchListData(1, listFilters);
    };

    const listCount = () => listItems ? listItems.length : 0;

    useImperativeHandle(ref, () => ({
        clearData,
        refreshData,
        listCount
    }));

    const fetchListData = async (toPage = 1, filters = listFilters) => {
        if (toPage === 0) {
            return;
        }

        if (isLoading) { return; }

        let _filters = [...(filters || []), ...(advancedSearchFilters || []), ...(additionalFilters || [])];

        sessionStorage.setItem(`${application}:list:page`, toPage);
        sessionStorage.setItem(`${application}:list:filters`, JSON.stringify(filters));
        sessionStorage.setItem(`${application}:list:advancedSearchFilters`, JSON.stringify(advancedSearchFilters || []));
        sessionStorage.setItem(`${application}:list:additionalFilters`, JSON.stringify(additionalFilters || []));
        sessionStorage.setItem(`${application}:list:hasSearch`, true);
        sessionStorage.setItem(`${application}:list:listSize`, listSize);

        setIsLoading(true);
        dataFetcher(_filters, listSize, toPage, ordering, (response) => {

            if(!response || !response.data) {
                setListItems([]);
                setTotalListPages(1);
                setTotalListItems(0);
                setIsLoading(false);
                setCurrentListPage(0);
                return;
            }
            let _data = [];
            let _pages = 1;
            if(!response.data.results) {
                _data = response.data;
                setTotalListPages(1);
                setTotalListItems(_data.count);
                setCurrentListPage(0);
            } else {
                _data = response.data.results;
                setTotalListPages(response.data.pages);
                setTotalListItems(response.data.count);
                if (response.data.pages >= toPage) {
                    setCurrentListPage(toPage);
                } else {
                    setCurrentListPage(0);
                }
                _pages = response.data.pages;
            }

            if (dataPreparation) {
                const _results = dataPreparation(_data);
                setListItems(_results);
            } else {
                setListItems(_data);
            }

            setListFilters(filters);
            
            setIsLoading(false);
            if (onDataChange) {
                onDataChange({
                    results: _data,
                    filters: _filters,
                    pages: _pages
                });
            }
        }, (error) => {
            console.error(error);
            setIsLoading(false);
            setListItems([]);
            setTotalListPages(1);
            setTotalListItems(0);

        });
    };

    const debouncedFetchListData = useAsyncDebounce(fetchListData, 1000);

    const handleSort = (column) => {
        if (!column || !useSorting) {
            return
        }

        const columnName = column.id;
        let newSortingDir = "asc";

        if (sortedColumn && columnName === sortedColumn.id) {
            newSortingDir = sortedDir === null ? "asc" : (sortedDir === "asc" ? "desc" : null);
        }

        if (newSortingDir === null) {
            setOrdering(null);
            setSortedDir(null);
            setSortedColumn(null);
            setCurrentListPage(1); // Reset page to 1
            debouncedFetchListData();
        }
        const isDesc = sortedDir === "asc";

        if (!columnName) {
            return
        }
        // Set the ordering parameter based on sorting direction
        let orderBy = newSortingDir === "desc" ? `-${columnName}` : columnName;
        orderBy = orderBy.replace(/\./g, '__')
        setOrdering(orderBy);
        setSortedColumn({
            id: columnName,
            desc: !isDesc,
        });
        setSortedDir(newSortingDir);
        setCurrentListPage(1); // Reset page to 1
        debouncedFetchListData();
    };

    // Table Functions and State
    const {
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        state
    } = useTable(
        {
            columns,
            defaultColumn,
            data: listItems || [],
            initialState: {
                selectedRowIds: { 0: true, },
                filters: listFilters || [],
                pageIndex: currentListPage || 1
            },
            manualPagination: true,
            manualFilters: true,
            manualSorting: true,
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            stickyHeader: true,
            // Trigger a callback when the sorting changes
            state: {
                sortedColumn,
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const handleChange = (_, currentPageNo) => {
        // currentPageNo is 0-indexed, so we add 1 to it
        gotoPage(currentPageNo);
        setCurrentListPage(currentPageNo + 1)
        fetchListData(currentPageNo + 1);
    }

    const doSearchItems = () => {
        gotoPage(0);
        setCurrentListPage(1); // Reset page to 1
        setSelectedRows([]);
        if(selectable && rowOnClick) rowOnClick(null);
        if(selectable && rowOnClick2) rowOnClick2(null);
        fetchListData();
    }

    const rowOnClickWrapper = (id) => {
        const _item = listItems.find((item) => item.id === id);
        if (multiple) {
            setSelectedRows((prevSelectedRows) => {
                if (prevSelectedRows && prevSelectedRows.some((row) => row.id === id)) {
                    return prevSelectedRows.filter((row) => row.id !== id);
                } else {
                    return [...prevSelectedRows, _item];
                }
            });
        } else {
            setSelectedRows([_item]);
        }

        if (rowOnClick) {
            rowOnClick(id);
        } else if (rowOnClick2) {
            rowOnClick2(multiple ? [...selectedRows, _item] : _item);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setListSize(parseInt(event.target.value, 10));
        setCurrentListPage(1); // Reset page to 1
    };

    const renderPagination = () => {
        if (currentListPage) {
            return (
                <TablePagination
                    component="div"
                    count={totalListItems}
                    page={currentListPage - 1}
                    onPageChange={handleChange}
                    rowsPerPage={listSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={viewType === "grid" ? [4, 8] : [3, 5, 10, 15, 20, 50, 100]}
                    labelRowsPerPage={t("label.table.rowsPerPage")}
                    labelDisplayedRows={() => ` Página ${currentListPage} de ${totalListPages}`}
                />
            )
        }
    }

    // Search is yet to be performed or empty dataset
    const renderNoDataInformation = () => {
        if (!isLoading && (listItems === null || listItems === undefined || listItems.length === 0)) {
            return (
                <TableRow>
                    <TableCell colSpan={columns.length}>
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                            <Inbox size="large" />
                            <Typography variant="subtitle">{t("message.table.noData")}</Typography>
                        </Stack>
                    </TableCell>
                </TableRow>
            )
        }

    };

    // Search is loading
    const renderLoadingSkeleton = () => {
        if (isLoading) {
            return (
                <TableRow>
                    <TableCell colSpan={columns.length + (selectable ? 1 : 0)}>
                        <Skeleton variant="rectangular" height={200 * (listSize / 5)} />
                    </TableCell>
                </TableRow>

            )
        }

    };

    const clearFilters = () => {
        setListFilters([]);
        setAdvancedSearchFilters([]);
        setHasSearch(false);
        setListItems([]);
        setTotalListPages(1);
        setTotalListItems(0);
        state.filters = [];
        sessionStorage.removeItem(`${application}:list:page`);
        sessionStorage.removeItem(`${application}:list:filters`);
        sessionStorage.removeItem(`${application}:list:additionalFilters`);
        sessionStorage.removeItem(`${application}:list:advancedSearchFilters`);

        if (onDataChange) {
            onDataChange({
                results: [],
                filters: [],
                pages: 0
            });
        }
    };

    const renderTableHeaderRow = (index, headerGroup) => {
        return (
            <TableRow
                key={index}
                id={`${props.id || 'table'}_table_header_row`}
                {...headerGroup.getHeaderGroupProps()}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {selectable ? (
                    <HeadTableCell sx={{width: "5%"}}></HeadTableCell>
                ) : null}
                {headerGroup.headers.map((column, index) => {
                    let headerProps = {
                        style: { minWidth: column.minWidth, width: column.width },
                    };

                    if (useSorting) {
                        headerProps = {
                            ...column.getSortByToggleProps(),
                            ...headerProps
                        }
                    }
                    return (
                        <HeadTableCell
                            key={index}
                            id={`${props.id || 'table'}_table_header_${index}`}
                            {...column.getHeaderProps(headerProps)}
                            sx={{ backgroundColor: theme.palette.mode === "dark" ? "#222b36" : "#FFF", p: 1.5}}
                        >
                            <Stack spacing={1} >
                                <Stack spacing={2} direction="row" onClick={() => handleSort(column)} sx={{ cursor: useSorting ? 'pointer' : '', justifyContent: column.align || "left" }}>
                                    {column.render("Header")}
                                    <span>
                                        {sortedColumn && sortedDir && column.id === sortedColumn.id ? (sortedDir === "desc" ? ' ↓' : ' ↑') : ''}
                                    </span>
                                </Stack>
                                {column.canFilter ? column.render("Filter", doSearchItems) : null}
                            </Stack>
                        </HeadTableCell>
                    )
                })}
            </TableRow>
        )
    }
    const renderTableRow = (index, row, _selectedRows) => {
        const isSelected = _selectedRows && _selectedRows.some((selectedRow) => selectedRow.id === row.original.id);
        return (
            <TableRow
                id={`${props.id || 'table'}_table_row_${index}`}
                key={index}
                onClick={() => rowOnClickWrapper(row.original.id)}
                size="small"
                sx={{ cursor: rowOnClick || rowOnClick2 ? "pointer" : "normal" }}
                selected={isSelected}
            >
                {selectable ? (
                <BodyTableCell sx={{textAlign: "center"}}>
                    <Checkbox
                        id={`${props.id || 'table'}_table_row_${index}_select`}
                        checked={isSelected}
                    />
                </BodyTableCell>
                ) : null}
                {row.cells.map((cell, cellIndex) => (
                    <BodyTableCell key={cellIndex} {...cell.getCellProps()} sx={{ p: 1.5, textAlign: columns[cellIndex].align || "left" }}>
                        {cell.render("Cell")}
                    </BodyTableCell>
                ))}
            </TableRow>
        );
    };

    const renderListView = () => {
        return (
            <IndieCard variant={props.outlined ? "outlined" : "elevation"}>
                <CardContent>
                    <Scrollbar >
                        <TableContainer id={`${props.id || 'table'}_table_container`}>
                            <Table id={`${props.id || 'table'}_table`}>
                                <TableHead  id={`${props.id || 'table'}_table_head`}>
                                    {headerGroups.map((headerGroup, index) => renderTableHeaderRow(index, headerGroup))}
                                </TableHead>
                                <TableBody  id={`${props.id || 'table'}_table_body`} {...getTableBodyProps()}>
                                    {!isLoading && page.map((row, index) => {
                                        prepareRow(row);
                                        return renderTableRow(index, row, selectedRows);
                                    })}
                                    {renderNoDataInformation()}
                                    {renderLoadingSkeleton()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    {renderPagination()}
                </CardContent>
            </IndieCard>
        )
    }

    const renderGridView = () => {

        if (isLoading) {
            return (
                <Box>
                    <Skeleton variant="rectangular" height={400} />
                </Box>
            )
        } else if (listItems === null || listItems === undefined || listItems.length === 0) {
            return (
                <Card>
                    <CardContent>
                        <Stack direction="row" spacing={2}>
                            <Inbox size="large" />
                            <Typography variant="subtitle">{t("message.table.noData")}</Typography>
                        </Stack>
                    </CardContent>
                </Card>

            )
        }

        return (
            <Card>
                <CardContent>
                    <Stack spacing={2}>
                        {renderPagination()}
                        <Grid2 container spacing={2}>
                            {page.map((row, index) => (
                                <Grid2 item size={{ xs: 12, sm: 6, lg: 3 }} key={index} >
                                    <CardComponent community={row.original} />
                                </Grid2>
                            ))}
                        </Grid2>
                        {renderPagination()}
                    </Stack>
                </CardContent>
            </Card>
        );
    };

    const renderMapView = () => {
        const _items = listItems.map((item) => {
            return {
                ...itemToMarker(item),
                infoWindow: itemInfoWindow(item)
            }
        });

        return (
            <IndieApplicationListMap items={_items} />
        )
    }

    useEffect(() => {
        // Check if the application has changed, if so, reset the list page and filters
        let _currentApplication = sessionStorage.getItem("list:application");
        if (_currentApplication !== application) {
            sessionStorage.removeItem(`${application}:list:page`);
            sessionStorage.removeItem(`${application}:list:filters`);
            sessionStorage.removeItem(`${application}:list:additionalFilters`);
            sessionStorage.removeItem(`${application}:list:advancedSearchFilters`);
            sessionStorage.removeItem(`${application}:list:hasSearch`);
            sessionStorage.removeItem(`${application}:list:listSize`);
            sessionStorage.setItem("list:application", application);
        }

        // Check if there is any saved state for the list, if so, restore it
        if (sessionStorage.getItem(`${application}:list:page`) !== null && currentListPage === 0) {
            let _page = toInteger(sessionStorage.getItem(`${application}:list:page`) || 1);
            setCurrentListPage(_page);
            let _filters = JSON.parse(sessionStorage.getItem(`${application}:list:filters`)) || [];
            setListFilters(_filters);
            let _additionalFilters = JSON.parse(sessionStorage.getItem(`${application}:list:additionalFilters`)) || [];
            let _advancedSearchFilters = JSON.parse(sessionStorage.getItem(`${application}:list:advancedSearchFilters`)) || [];
            setAdvancedSearchFilters(_advancedSearchFilters);
            state.filters = [..._filters, ..._additionalFilters, ..._advancedSearchFilters];
            let _hasSearch = sessionStorage.getItem(`${application}:list:hasSearch`) || false;
            setHasSearch(_hasSearch);
            setListSize(toInteger(sessionStorage.getItem(`${application}:list:listSize`) || 10));
        }

        setIsSettingUp(false);
    }, []);

    useEffect(() => {
        fetchListData(currentListPage, listFilters);

    }, [refresh, listSize]);

    useEffect(() => {
        if (hasSearch) {
            fetchListData(1);
        }
    }, [advancedSearchFilters, hasSearch]);

    useEffect(() => {
        if (autoLoad) {
            fetchListData(1);
        }
    }, []);

    if (isSettingUp) {
        return (
            <Box>
                <Skeleton variant="rectangular" height={400} />
            </Box>
        )
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={() => { }}
        >
            {() => (
                <Form>
                    <Stack spacing={2}>
                        {showAppBar && (
                            <Grid2 container spacing={2}>
                                <Grid2 size={6}>
                                    <Stack direction="row" spacing={1} justifyContent="flex-start">
                                        {(appBar || []).map((item, index) => item)}
                                    </Stack>
                                </Grid2>
                                <Grid2 size={6}>
                                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                                        {showListView && <IconButton sx={{ color: viewType === 'list' ? "primary.main" : "default" }} onClick={() => {
                                            setViewType("list");
                                            setListSize(10);
                                        }}>
                                            <ViewListRounded />
                                        </IconButton>}
                                        {showGridView && (
                                            <IconButton sx={{ color: viewType === 'grid' ? "primary.main" : "default" }} onClick={() => {
                                                setViewType("grid");
                                                setListSize(4);
                                            }}>
                                                <GridViewRounded />
                                            </IconButton>
                                        )}
                                        {showMapView && (
                                            <IconButton sx={{ color: viewType === 'map' ? "primary.main" : "default" }} onClick={() => setViewType("map")}>
                                                <MapRounded />
                                            </IconButton>
                                        )}
                                        {showAdvancedSearch && (
                                            <IconButton onClick={setOpenAdvancedSearch}>
                                                <FilterAlt />
                                            </IconButton>
                                        )}
                                        <IconButton onClick={clearFilters}>
                                            <FilterAltOff />
                                        </IconButton>
                                        <IconButton id={`${props.id || 'table'}_table_refresh_button`} onClick={() => fetchListData(1, state.filters)}>
                                            <RefreshRounded />
                                        </IconButton>
                                    </Stack>
                                </Grid2>
                            </Grid2>)}
                        {viewType === 'list' && renderListView()}
                        {viewType === 'grid' && renderGridView()}
                        {viewType === 'map' && renderMapView()}
                        <AdvancedSearchDialog open={openAdvancedSearch} onClose={() => setOpenAdvancedSearch(false)} onSearch={(values) => {
                            setHasSearch(true);
                            setAdvancedSearchFilters(values);
                            setOpenAdvancedSearch(false);
                        }} filterList={advancedFilterList} initialValues={advancedSearchFilters} />
                    </Stack>
                </Form>
            )}
        </Formik>

    );
});


const DefaultFilter = React.memo(({column, fetchData, state, ...props}) => {

    const theme = useTheme();
    const filterValue = column.filterValue;
    const setFilter = column.setFilter;

    return (
        <TextField
            {...props}
            id={`filter_${column.id.replaceAll('.', '_')}`}
            variant="outlined"
            size="small"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value);

            }}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    fetchData(1, state.filters);
                }
            }}

            sx={{
                backgroundColor:
                    theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                borderRadius: "8px",
                input: { textAlign: column.align || "left" },
                ...props.sx
            }}
            autocomplete="new-password"
            data-lpignore="true"
        />
    );
});

export const DateFilter = React.memo(({ column, setListPage, fetchData, state }) => {

    return (<DateTableFilter
        id={`filter_${column.id.replaceAll('.', '_')}`}
        column={column}
        setListPage={setListPage}
        onKeyUp={(e) => {
            if (e.key === "Enter") {
                fetchData(1, state.filters);
            }
        }}
        sx={{ input: { textAlign: column.align || "left" } }}
    />)
});

export const SelectOptionsFilter = React.memo(({ column, fetchData, state, options, ...params }) => {

    const { filterValue, setFilter } = column;
    const theme = useTheme();
    const handleFilterChange = (value) => {
        setFilter(value && value.id ? value.id : undefined);
    };

    return (
        <Autocomplete
            size="small"
            id={`${params.id}_filter`}
            options={options}
            value={filterValue ? options.filter((o) => o.id === filterValue)[0] : undefined}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    fetchData(1, state.filters);
                }
            }}
            onChange={(e, value) => handleFilterChange(value)}
            renderInput={(params) => <TextField {...params} autoComplete='off'
                sx={{
                    backgroundColor:
                        theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                    borderRadius: "8px",
                    minWidth: "150px",
                    zIndex: (theme) => theme.zIndex.drawer - 1
                }} />}
        />
    );
});

export const SelectYesNoFilter = React.memo(({ column, fetchData, state }) => {

    const { filterValue, setFilter } = column;
    const { t } = useTranslation();
    const theme = useTheme();
    const options = [
        { label: t('label.form.all'), id: "" },
        { label: t('label.form.yes'), id: "true" },
        { label: t('label.form.no'), id: "false" },
    ];

    const handleFilterChange = (columnId, event, value) => {
        setFilter(value && value.id ? value.id : undefined);
    };

    return (
        <Autocomplete
            size="small"
            id="combo-box-demo"
            options={options}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    fetchData(1, state.filters);
                }
            }}
            value={filterValue ? options.filter((o) => o.id === filterValue)[0] : options.filter((o) => o.id === "")[0]}
            onChange={(e, value) => handleFilterChange(column.name, e, value)}
            renderInput={(params) => <TextField {...params} autoComplete='off'
                sx={{
                    backgroundColor: theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                    borderRadius: "8px",
                    minWidth: "100px",
                    zIndex: (theme) => theme.zIndex.drawer - 1
                }} />} />
    );
});

const AdvancedSearchDialog = ({ open, onClose, onSearch, filterList, initialValues }) => {

    const { t } = useTranslation();

    const [formValues, setFormValues] = useState({});

    const renderFilter = (filter, index, formik) => {
        if (filter.type === "exact") {

        } else if (filter.type === "date") {

        } else if (filter.type === "date-range") {
            return (
                <Stack spacing={2} key={index}>
                    <Typography>{t(filter.label)}</Typography>
                    <Stack direction="row" spacing={2}>
                        <IndieDesktopDatePicker
                            id={`start_${filter.id}`} name={`start_${filter.name || filter.id}`}
                            label={"De"}
                            onChange={(newDate) => formik.setFieldValue(`start_${filter.name || filter.id}`, newDate.format(DRF_DATE_FORMAT))}
                            value={formik.values[`start_${filter.name || filter.id}`]} />
                        <IndieDesktopDatePicker
                            id={`end_${filter.id}`} name={`end_${filter.name || filter.id}`} label={"A"}
                            onChange={(newDate) => formik.setFieldValue(`end_${filter.name || filter.id}`, newDate.format(DRF_DATE_FORMAT))}
                            value={formik.values[`end_${filter.name || filter.id}`]} />
                    </Stack>
                </Stack>
            )
        }
    }

    const convertValuesToFilters = (values) => {

        let filters = [];
        for (let key in values) {
            if (values[key] !== null) {
                filters.push({
                    id: key,
                    name: key,
                    value: values[key]
                });
            }
        }

        onSearch(filters);
    }

    const getInitialValues = () => {
        let _initialValues = {};

        if (initialValues && initialValues.length > 0) {
            initialValues.map((filter) => _initialValues[`${filter.name}`] = filter.value)
            return _initialValues;
        }


        (filterList || []).map((filter) => {
            if (filter.type === "date-range") {
                _initialValues[`start_${filter.id}`] = null;
                _initialValues[`end_${filter.id}`] = null;
            } else {
                _initialValues[filter.id] = null;
            }
        });
        return _initialValues;
    }

    useEffect(() => {
        if (open) {
            setFormValues(getInitialValues());
        }
    }, [open]);

    return (
        <Dialog open={open}>
            <Formik
                onSubmit={convertValuesToFilters}
                initialValues={formValues}
                enableReinitialize
            >
                {(formik) => (
                    <Form>
                        <DialogContent>
                            <DialogTitle>Pesquisa Avançada</DialogTitle>
                            <DialogContent>

                                <Stack direction="column" spacing={2}>
                                    {(filterList || []).map((filter, index) => renderFilter(filter, index, formik))}
                                </Stack>


                            </DialogContent>
                            <DialogActions>
                                <IndieCancelButton onClick={onClose} />
                                <IndieSearchButton type="submit" />
                            </DialogActions>
                        </DialogContent>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
export default ApplicationList;